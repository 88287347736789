import SingleViewComponent from '../components/singleViewComponent';
function SingleView() {
    return (
        <div>
            <SingleViewComponent />
        </div>
    );
}

export default SingleView;
