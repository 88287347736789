import WriteComponent from '../components/writeComponent';

function Write() {
    return (
        <div>
            <WriteComponent />
        </div>
    );
}

export default Write;
